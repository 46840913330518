const config = {
  nodeEnv: process.env.NODE_ENV,
  store: {
    localStorageKey: process.env.REACT_APP_STORE_LOCAL_STORAGE_KEY,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    timeout: process.env.REACT_APP_API_TIMEOUT,
    mockEnabled: process.env.REACT_APP_API_MOCK_ENABLED === 'true',
    mockResponseDelay: process.env.REACT_APP_API_MOCK_RESPONSE_DELAY,
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  },
};

export default config;
