import React from 'react';
import { Button, Card, Typography } from '../common';

const styles = {
  buttonContainer: {
    marginTop: 10,
  },
  cancelButton: {
    marginRight: 10,
  },
  submitButton: {
    marginLeft: 10,
  },
};

const { Text } = Typography;

const StudentConfirmationCard = ({ intl, name, onCancel, onSubmit }) => {
  return (
    <Card>
      <div>
        <Text>{intl.message(name)}</Text>
      </div>
      <div style={styles.buttonContainer}>
        <Button style={styles.cancelButton} type="secondary" onClick={onCancel}>
          {intl.cancelButton}
        </Button>
        <Button style={styles.submitButton} type="primary" onClick={onSubmit}>
          {intl.submitButton}
        </Button>
      </div>
    </Card>
  );
};

export default StudentConfirmationCard;
