import React from 'react';
import {
  Modal,
  CheckCircleOutlined,
  StopOutlined,
  Typography,
} from '../common';

const styles = {
  modalContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  passIcon: {
    color: '#28a745',
    fontSize: 96,
    marginBottom: 24,
  },
  failIcon: {
    color: '#DC3545',
    fontSize: 96,
    marginBottom: 24,
  },
  reCheckInActionContainer: {
    marginTop: 16,
  },
};

const { Title, Text, Link } = Typography;

const StudentStatusModal = ({
  visible,
  onOk,
  onCancel,
  onReCheckIn,
  student,
  intl,
}) => {
  let title = '';
  let message = '';
  let Icon = () => {
    return <div />;
  };
  let iconStyle = {};

  if (student) {
    title = student.isShowingSymptoms
      ? intl.failTitle(student)
      : intl.passTitle(student);

    message = student.isShowingSymptoms
      ? intl.failMessageText(student)
      : intl.passMessageText(student);

    Icon = student.isShowingSymptoms ? StopOutlined : CheckCircleOutlined;
    iconStyle = student.isShowingSymptoms ? styles.failIcon : styles.passIcon;
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div style={styles.modalContentContainer}>
        <Icon style={iconStyle} />
        <Title level={4}>{message}</Title>
        <div style={styles.reCheckInActionContainer}>
          <Text>{intl.reCheckInActionLinkPrefix}</Text>
          <Link onClick={onReCheckIn}>{intl.reCheckInActionLink}</Link>
          <Text>{intl.reCheckInActionLinkSuffix}</Text>
        </div>
      </div>
    </Modal>
  );
};

export default StudentStatusModal;
