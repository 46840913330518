import deepmerge from 'deepmerge';
import en from './en.js';
import es from './es.js';
import usIcon from '../assets/icons/icon_flag_us.png';
import mexicoIcon from '../assets/icons/icon_flag_mexico.png';

export const locales = {
  en: {
    key: 'en',
    label: 'English',
    icon: usIcon,
  },
  es: {
    key: 'es',
    label: 'Spanish',
    icon: mexicoIcon,
  },
};

export const messages = {
  en,
  es: deepmerge(en, es),
};

export const defaultLocale = 'en';
