const es = {
  app: {
    header: {
      userMenu: {
        signOut: 'Cerrar Sesión',
      },
    },
    footer: {
      termsOfUseLinkText: 'Términos de Uso',
      privacyPolicyLinkText: 'Política de Privacidad',
    },
  },
  loginPage: {
    studentLinkText: '¿No es estudiante? Ir al inicio de sesión para personal.',
    staffLinkText: '¿No es empleado? Ir al inicio de sesión para estudiantes.',
    loginForm: {
      titleText: 'Evaluación de Síntomas de COVID-19',
      appMessage:
        'En un esfuerzo por proteger nuestra comunidad de enfermedades, pedimos que todo el personal y estudiantes se tomen un minuto todas las mañanas para responder esta encuesta que está basada en los lineamientos del CDC.',
      usernameLabel: 'Id de Estudiante',
      usernameRequiredMessage:
        'Por favor ingrese la identificación de estudiante.',
      usernameHelpMessage:
        'Su número de identificación de estudiante. Usualmente es un número de 9 dígitos como: 123456789.',
      passwordLabel: 'Fecha de Nacimiento del Estudiante',
      passwordRequiredMessage:
        'Por favor ingrese la fecha de nacimiento del estudiante.',
      passwordHelpMessage:
        'La fecha de nacimiento del estudiante en el formato mes/dia/año. Por ejemplo, ingrese Enero 10 de 2008 como 1/10/2008.',
      parentCheckboxLabel: 'Estoy de acuerdo con los términos de uso.',
      parentRequiredMessage:
        'You must read and agree to the terms of use before proceeding.',
      loginButton: 'Iniciar Sesión',
      googleButton: 'FSD145 Inicio de Sesión de Personal',
      loginErrorMessage:
        'Inicio de sesión fallido. Por favor confirme el Id de estudiante y la fecha de nacimiento.',
    },
  },
  checkInPage: {
    titleText: name => `${name}`,
    dateText: date =>
      `Esta evaluación de síntomas de COVID-19 es para el día ${date}`,
    checkInForm: {
      certifyQuestionText: name =>
        `¿${name} ha experimentado alguno de los siguientes síntomas en las 24 horas pasadas?`,
      symptoms: {
        fever: 'Fiebre o escalofríos',
        coughing: 'Tos',
        'shortness-of-breath': 'Dificultad para respirar',
        fatigue: 'Fatiga/Cansancio',
        'body-aches': 'Dolor muscular o corporal',
        'loss-of-taste-or-smell': 'Nueva pérdida de sabor u olor',
        'sore-throat': 'Dolor de garganta',
        congestion: 'Congestión o secreción nasal',
        nausea: 'Náusea o vómito',
        diarrhea: 'Diarrea',
      },
      temperatureQuestionText: name =>
        `¿Cuál es la temperatura actual de ${name}?`,
      degree: '° F',

      temperatureErrorText:
        'Ingrese una temperatura corporal válida en grados Fahrenheit.',
      submitButton: 'Enviar Certificado',
      errorMessageText: 'Debe reportar todos los síntomas de la lista',
      questionOptions: {
        noButton: 'No',
        yesButton: 'Si',
      },
    },
  },
  recommendationPage: {
    selfCheckIn: {
      passTitle: () => 'Gracias por registrarse!',
      failTitle: () => '¡Usted Presenta Síntomas de COVID-19!',
      passMessageText: () =>
        'Basado en sus respuestas, usted está autorizado para seguir con el horario apropiado para el día de hoy.',
      failMessageText: () =>
        'Basado en sus respuestas, usted debe quedarse en casa el día de hoy y comunicarse con el personal adecuado.',
    },
    checkInForOthers: {
      passTitle: name => `You have checked in ${name}!`,
      failTitle: name => `${name} is Showing COVID-19 Symptoms!`,
      passMessageText: name =>
        `Based on your responses, ${name} cleared to follow the appropriate schedule for today.`,
      failMessageText: name =>
        `Based on your responses, ${name} should stay home today and contact the appropriate personnel.`,
    },
    actions: {
      goToHome: 'Inicio',
      goToListPeople: 'Back to Student List',
      signOut: 'Cerrar Sesión',
    },
  },
  staffHomePage: {
    titleText: user => user.name,
    selfCheckInOptionTitle: 'Auto Evaluación',
    selfCheckInOptionDescription:
      'Realizar una auto evaluación para síntomas actuales de COVID-19.',
    checkInOthersOptionTitle: 'Evaluar a Otros',
    checkInOthersOptionDescription:
      'Realizar una evaluación de síntomas de COVID-19 para otra persona.',
  },
  studentsCheckInPage: {
    titleText: 'Evaluación de Síntomas de COVID-19',
    studentFilters: {
      studentNameSearchLabel: 'Seleccione un estudiante',
      allStudentsLabel: 'All Students',
    },
    studentList: {
      studentListItem: {
        studentStatusLabels: {
          unknown: 'No se ha registrado todavía',
          showingSymptoms: 'Muestra síntomas',
          notShowingSymptoms: 'No muestra síntomas',
        },
      },
    },
    studentStatusModal: {
      passTitle: student => `Se registró a las ${student.checkInTime}`,
      failTitle: student => `Se registró a las ${student.checkInTime}`,
      passMessageText: student =>
        `¡${student.name} no presenta síntomas el día de hoy!`,
      failMessageText: student =>
        `¡${student.name} presenta síntomas de COVID-19!`,
      reCheckInActionLinkPrefix:
        'Si este resultado necesita ser actualizado, usted puede ',
      reCheckInActionLink: 'rehacer el cuestionario de evaluación',
      reCheckInActionLinkSuffix: '.',
    },
  },
};

export default es;
