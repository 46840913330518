import MockAdapter from 'axios-mock-adapter';
import omit from 'lodash/omit';
import { users } from './data';

const TEMPERATURE_THRESHOLD = 100;

const isShowingSymptoms = checkIn => {
  return (
    checkIn.temperature > TEMPERATURE_THRESHOLD ||
    !!checkIn.symptomResults.find(sr => sr.isShowingSymptom)
  );
};

const AxiosMock = {
  init: (axiosInstance, delayResponse) => {
    AxiosMock.mock = new MockAdapter(axiosInstance, { delayResponse });

    AxiosMock.mock.onPost('/authenticateWithPassword').reply(config => {
      console.log('[API MOCK] ', config);
      const { username, password } = JSON.parse(config.data);

      const user = users.find(
        u =>
          u.auxiliary.username === username && u.auxiliary.password === password
      );

      if (user) {
        return [
          200,
          {
            identity: omit(user, ['auxiliary']),
            accessToken: 'access-token',
          },
        ];
      } else {
        return [401, { code: 100, message: 'Invalid username or password' }];
      }
    });

    AxiosMock.mock.onPost('/authenticateWithGoogleOAuthToken').reply(config => {
      console.log('[API MOCK] ', config);
      const user = users.find(u => u.id === 'staff-1');

      return [
        200,
        {
          identity: omit(user, ['auxiliary']),
          accessToken: 'access-token',
        },
      ];
    });

    AxiosMock.mock.onPost('/screenPerson').reply(config => {
      console.log('[API MOCK] ', config);
      const { checkIn } = JSON.parse(config.data);

      return [200, { passed: isShowingSymptoms(checkIn) }];
    });

    AxiosMock.mock.onGet('/listPeople').reply(config => {
      console.log('[API MOCK] ', config);
      const {
        userId,
        filter: { group: groupFilter, text: textFilter },
      } = config.params;

      const user = users.find(u => u.id === userId);
      let selectedGroupIds = user.auxiliary.managedGroups.map(ug => ug.id);

      if (groupFilter) {
        selectedGroupIds = [groupFilter];
      }

      let selectedUsers = users.filter(
        u =>
          // const userGroupIds = u.userGroups.map(ug => ug.id);
          u.auxiliary.userGroups.filter(ugId => selectedGroupIds.includes(ugId))
            .length > 0
      );

      if (textFilter) {
        selectedUsers = selectedUsers.filter(
          u =>
            u.id.toLowerCase().includes(textFilter.toLowerCase()) ||
            u.givenName.toLowerCase().includes(textFilter.toLowerCase()) ||
            u.familyName.toLowerCase().includes(textFilter.toLowerCase())
        );
      }

      return [
        200,
        {
          people: selectedUsers.map(u => ({
            ...omit(u, ['auxiliary']),
            status: u.auxiliary.status,
          })),
        },
      ];
    });

    AxiosMock.mock.onGet('/getPermissions').reply(config => {
      console.log('[API MOCK] ', config);
      const { userId } = config.params;

      const user = users.find(u => u.id === userId);

      return [
        200,
        {
          permissions: user.auxiliary.permissions,
          managedGroups: user.auxiliary.managedGroups,
        },
      ];
    });

    AxiosMock.mock.onGet('/getStatus').reply(config => {
      console.log('[API MOCK] ', config);
      const { userId } = config.params;

      const user = users.find(u => u.id === userId);

      return [
        200,
        {
          status: user.auxiliary.status,
        },
      ];
    });
  },
};

export default AxiosMock;
