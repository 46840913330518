import { types, flow } from 'mobx-state-tree';
import { withServices, withLoadingStatus } from '../../extensions';

const AppStore = types.compose(
  withLoadingStatus(),
  withServices,
  types
    .model('AppStore', {
      isReady: types.maybe(types.boolean),
      symptoms: types.array(types.string),
    })
    .actions(self => ({
      getSymptoms: flow(function* getSymptoms() {
        self.startFetching();

        try {
          let response = yield self.services.api.getSymptomList();
          const { symptoms } = response.data;

          self.symptoms = symptoms;

          self.endFetching();
          return true;
        } catch (e) {
          self.endFetching(e);
          return false;
        }
      }),
    }))
);

export default AppStore;
