import React from 'react';
import { Modal } from '../common';

const BasicPolicyModal = ({ visible, title, info, onOk, onCancel }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      className="basic-policy-modal"
    >
      <p>{info}</p>
    </Modal>
  );
};

export default BasicPolicyModal;
