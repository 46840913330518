import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { withServices } from '../../services';
import {
  Button,
  Typography,
  CheckCircleOutlined,
  StopOutlined,
} from '../../components/common';

const styles = {
  pageContainer: {},
  passIcon: {
    color: '#28a745',
    fontSize: 96,
    marginBottom: 24,
  },
  failIcon: {
    color: '#DC3545',
    fontSize: 96,
    marginBottom: 24,
  },
  additionalInfo: {
    textAlign: 'left',
    marginTop: 50,
  },
  footer: {
    paddingTop: 24,
  },
  homeButton: {
    marginRight: 8,
  },
};

const { Title } = Typography;

const RecommendationPage = ({
  intl: {
    messages: { recommendationPage: intl },
  },
  userStore: { user, managedUsers, signOut },
  services: { navigation },
}) => {
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  let resultIntl = intl.selfCheckIn;
  let checkInFor = user;
  let isSelfCheckIn = true;

  if (search['user-id']) {
    resultIntl = intl.checkInForOthers;
    checkInFor = managedUsers.find(s => s.id === search['user-id']);
    isSelfCheckIn = false;
  }

  const onSignOut = () => {
    signOut();

    if (user.isStaff) {
      history.push(`${navigation.login.toUrl()}?auth=google`);
    } else {
      history.push(`${navigation.login.toUrl()}?auth=password`);
    }
  };

  const goTo = url => {
    history.push(url);
  };

  return (
    <div style={styles.pageContainer}>
      {!checkInFor.isShowingSymptoms ? (
        <Fragment>
          <CheckCircleOutlined style={styles.passIcon} />
          <Title level={2}>{resultIntl.passTitle(checkInFor.name)}</Title>
          <Title level={4}>{resultIntl.passMessageText(checkInFor.name)}</Title>
          <div style={styles.additionalInfo}>
            {resultIntl.additionalPassInfoText}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <StopOutlined style={styles.failIcon} />
          <Title level={2}>{resultIntl.failTitle(checkInFor.name)}</Title>
          <Title level={4}>{resultIntl.failMessageText(checkInFor.name)}</Title>
          <Title level={4}> {resultIntl.phone1}</Title>
          <div style={styles.additionalInfo}>
            {resultIntl.additionalFailInfoText}
          </div>
        </Fragment>
      )}
      <div style={styles.footer}>
        {user.isStaff ? (
          <Fragment>
            {isSelfCheckIn ? (
              <Button
                style={styles.homeButton}
                onClick={goTo.bind(this, navigation.home.toUrl())}
              >
                {intl.actions.goToHome}
              </Button>
            ) : (
              <Button
                style={styles.homeButton}
                onClick={goTo.bind(this, navigation.listPeople.toUrl())}
              >
                {intl.actions.goToListPeople}
              </Button>
            )}
            <Button onClick={onSignOut}>{intl.actions.signOut}</Button>
          </Fragment>
        ) : (
          <Button onClick={onSignOut}>{intl.actions.signOut}</Button>
        )}
      </div>
    </div>
  );
};

export default withServices(
  injectIntl(inject('userStore')(observer(RecommendationPage)))
);
