import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withServices } from '../../services';
import { Typography, Row, Col } from '../../components/common';
import StudentList from '../../components/staff/StudentList';
import StudentGroupFilter from '../../components/staff/StudentGroupFilter';
import StudentNameSearch from '../../components/staff/StudentNameSearch';
import StudentStatusModal from '../../components/staff/StudentStatusModal';

const styles = {
  studentFiltersContainer: {
    margin: '8px 0',
  },
  studentGroupFilterContainer: {
    margin: '8px 0',
  },
  studentNameSearchContainer: {
    margin: '8px 0',
  },
};

const { Title } = Typography;

const defaultStudentGroup = 'all';

const TIMEOUT_CLEAR_TIME = 1000;
const LENGTH_TO_CLEAR = 9;

const is_numeric = char => /^\d+$/.test(char);

const containsCharacter = value => /[a-z]/gi.test(value);

const StudentsCheckInPage = ({
  intl: {
    messages: { studentsCheckInPage: intl },
  },
  userStore: { user, managedUsers, getManagedUsers },
  services: { navigation },
}) => {
  const history = useHistory();
  const [studentGroup, setStudentGroup] = useState(defaultStudentGroup);
  const [studentId, setStudentId] = useState(null);
  const [shownStudent, setShownStudent] = useState(null);
  const [listenForReader, setListenForReader] = useState(true);
  const [clearOnNextInput, setClearOnNextInput] = useState(false);
  const [clearInputTimeout, setClearInputTimeOut] = useState(null);

  useEffect(() => {
    const filter = {};

    if (studentGroup !== 'all') {
      filter.group = studentGroup;
    }

    if (studentId) {
      filter.text = studentId;
    }

    getManagedUsers(filter);
  }, [getManagedUsers, studentGroup, studentId]);

  const inputManager = useCallback(
    newValue => {
      const lastChar = newValue.charAt(newValue.length - 1);

      if (clearInputTimeout) {
        clearTimeout(clearInputTimeout);
        setClearInputTimeOut(null);
      }

      if (clearOnNextInput) {
        setClearOnNextInput(false);
        newValue = lastChar;
      }

      if (containsCharacter(newValue) && is_numeric(lastChar)) {
        newValue = lastChar;
      }

      if (newValue.length >= LENGTH_TO_CLEAR) {
        const timeout = setTimeout(
          () => setClearOnNextInput(true),
          TIMEOUT_CLEAR_TIME
        );
        setClearInputTimeOut(timeout);
      }

      return newValue;
    },
    [clearInputTimeout, clearOnNextInput]
  );

  const onKeyPress = useCallback(
    e => {
      const { key, keyCode } = e;

      if (listenForReader && keyCode !== 13) {
        setStudentId(prev => {
          return inputManager(`${prev}${key}`);
        });
      }
    },
    [listenForReader, inputManager]
  );

  useEffect(() => {
    document.addEventListener('keypress', onKeyPress);

    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
  }, [onKeyPress]);

  if (!managedUsers) {
    // Loading state
    return (
      <div style={styles.pageContainer}>
        <Title level={2} style={styles.title}>
          {intl.titleText}
        </Title>
      </div>
    );
  }

  // TODO: remove when known issue with mst array mutations is figured out
  JSON.stringify(managedUsers);

  const studentGroups = [
    {
      id: 'all',
      name: intl.studentFilters.allStudentsLabel,
    },
    ...user.managedGroups,
  ];

  const students = managedUsers;

  const onStudentGroupChange = updatedStudentGroup => {
    setStudentGroup(updatedStudentGroup);
    setStudentId(null);
  };

  const onCheckStudentStatus = studentId => {
    const statusStudent = students.find(s => s.id === studentId);
    setShownStudent(statusStudent);
  };

  const clearShownStudent = () => {
    setShownStudent(null);
  };

  const onCheckInStudent = id => {
    history.push(`${navigation.checkIn.toUrl()}?user-id=${id}`);
  };

  const onStudentNameSearchChange = e => {
    setStudentId(inputManager(e.target.value));
  };

  const inputTakeControl = () => {
    setListenForReader(false);
  };

  const keyPressTakeControl = () => {
    if (studentId === '') {
      setListenForReader(true);
    }
  };

  return (
    <div style={styles.pageContainer}>
      <Title level={2} style={styles.title}>
        {intl.titleText}
      </Title>
      <div style={styles.studentListContainer}>
        <Row gutter={16} style={styles.studentFiltersContainer}>
          <Col xs={24} md={12} style={styles.studentGroupFilterContainer}>
            <StudentGroupFilter
              studentGroups={studentGroups}
              defaultStudentGroup={defaultStudentGroup}
              value={studentGroup}
              onChange={onStudentGroupChange}
            />
          </Col>
          <Col xs={24} md={12} style={styles.studentNameSearchContainer}>
            <StudentNameSearch
              placeholder={intl.studentFilters.studentNameSearchLabel}
              value={studentId}
              onChange={onStudentNameSearchChange}
              onBlur={keyPressTakeControl}
              onFocus={inputTakeControl}
            />
          </Col>
        </Row>
        <StudentList
          students={students}
          onCheckStudentStatus={onCheckStudentStatus}
          onCheckInStudent={onCheckInStudent}
          intl={intl.studentList}
        />
        <StudentStatusModal
          visible={shownStudent !== null}
          student={shownStudent}
          onOk={clearShownStudent}
          onCancel={clearShownStudent}
          onReCheckIn={onCheckInStudent.bind(
            this,
            shownStudent && shownStudent.id
          )}
          intl={intl.studentStatusModal}
        />
      </div>
    </div>
  );
};

export default withServices(
  injectIntl(inject('userStore')(observer(StudentsCheckInPage)))
);
