import React from 'react';
import { Card, Typography } from '../common';

const styles = {
  card: {
    width: 300,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  userIcon: {
    color: '#e45101',
    fontSize: 96,
    marginBottom: 24,
  },
};

const { Title, Text } = Typography;

const StaffOptionCard = ({ Icon, title, description, onClick }) => {
  return (
    <Card style={styles.card} className="staff-option-card" onClick={onClick}>
      <Icon style={styles.userIcon} />
      <div style={styles.cardContent}>
        <Title level={4}>{title}</Title>
        <Text>{description}</Text>
      </div>
    </Card>
  );
};

export default StaffOptionCard;
