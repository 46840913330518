import { fetchServices } from '../../services';
import { types } from 'mobx-state-tree';

const withServices = types.model({}).views(self => ({
  get services() {
    return fetchServices();
  },
}));

export default withServices;
