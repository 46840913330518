import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { withServices } from '../../services';
import LoginForm from '../../components/auth/LoginForm';
import { Divider } from '../../components/common';

const SUPPORTED_AUTH = ['password', 'google'];

const styles = {
  pageContainer: {},
  link: {
    color: '#e45101',
    textDecoration: 'none',
    backgroundDolor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#f07426',
    },
  },
};

const LoginPage = ({
  intl: {
    messages: { loginPage: intl },
  },
  services: { navigation },
  userStore: { signIn, signInWithGoogle },
}) => {
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  let auth = search.auth ? search.auth.toLowerCase() : 'password';
  auth = SUPPORTED_AUTH.includes(auth) ? auth : 'password';
  const isStudent = auth === 'password';

  const [showError, setShowError] = useState(false);

  const redirectUser = (isSuccessful, authenticatedUser) => {
    setShowError(!isSuccessful);

    if (isSuccessful) {
      let redirectTo = navigation.root.toUrl();

      if (authenticatedUser.isStaff) {
        redirectTo = navigation.home.toUrl();
      } else if (
        authenticatedUser.birthDate &&
        authenticatedUser.needsConsent
      ) {
        redirectTo = navigation.consent.toUrl();
      } else if (authenticatedUser.hasCheckedIn) {
        redirectTo = navigation.recommendation.toUrl();
      } else {
        redirectTo = navigation.checkIn.toUrl();
      }

      history.push(redirectTo);
    }
  };

  const onSubmit = values => {
    signIn(values.username, values.password).then(
      ({ isSuccessful, authenticatedUser }) => {
        redirectUser(isSuccessful, authenticatedUser);
      }
    );
  };

  const onGoogleSubmit = info => {
    signInWithGoogle(info.tokenId).then(
      ({ isSuccessful, authenticatedUser }) => {
        redirectUser(isSuccessful, authenticatedUser);
      }
    );
  };

  return (
    <div style={styles.pageContainer}>
      <LoginForm
        intl={intl.loginForm}
        onSubmit={onSubmit}
        onGoogleSubmit={onGoogleSubmit}
        auth={auth}
        showError={showError}
      />
      <Divider />
      {isStudent ? (
        <Link to={`${navigation.login.toUrl()}?auth=google`}>
          {intl.studentLinkText}
        </Link>
      ) : (
        <Link to={`${navigation.login.toUrl()}?auth=password`}>
          {intl.staffLinkText}
        </Link>
      )}
    </div>
  );
};

export default withServices(
  injectIntl(inject('userStore')(observer(LoginPage)))
);
