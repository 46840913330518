import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider as StoreProvider } from 'mobx-react';
import { AnonymousRoute, AuthenticatedRoute } from './navigation';
import AppContainer from './components/layout/AppContainer';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import PageContainer from './components/layout/PageContainer';
import LoginPage from './pages/auth/LoginPage';
import CheckInPage from './pages/checkIn/CheckInPage';
import RecommendationPage from './pages/checkIn/RecommendationPage';
import StaffHomePage from './pages/staff/StaffHomePage';
import StudentConsentPage from './pages/student/StudentConsentPage';
import StudentsCheckInPage from './pages/staff/StudentsCheckInPage';
import { locales, messages, defaultLocale } from './intl';
import config from './config';
import { initServices } from './services';
import { createStore } from './store';
import './App.less';

const { navigation } = initServices();
const store = createStore(config.store);

const App = () => {
  const [locale, setLocale] = useState(defaultLocale);

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      <StoreProvider appStore={store.appStore} userStore={store.userStore}>
        <Router>
          <AppContainer
            locales={locales}
            locale={locale}
            onLocaleChange={setLocale}
          >
            <Header />
            <PageContainer>
              <Switch>
                <AnonymousRoute path={navigation.login.path}>
                  <LoginPage />
                </AnonymousRoute>

                <AuthenticatedRoute exact path={navigation.root.path}>
                  <Redirect to={navigation.home.path} />
                </AuthenticatedRoute>

                <AuthenticatedRoute path={navigation.home.path}>
                  <StaffHomePage />
                </AuthenticatedRoute>

                <AuthenticatedRoute path={navigation.consent.path}>
                  <StudentConsentPage />
                </AuthenticatedRoute>

                <AuthenticatedRoute path={navigation.checkIn.path}>
                  <CheckInPage />
                </AuthenticatedRoute>

                <AuthenticatedRoute path={navigation.recommendation.path}>
                  <RecommendationPage />
                </AuthenticatedRoute>

                <AuthenticatedRoute path={navigation.listPeople.path}>
                  <StudentsCheckInPage />
                </AuthenticatedRoute>
              </Switch>
            </PageContainer>
            <Footer />
          </AppContainer>
        </Router>
      </StoreProvider>
    </IntlProvider>
  );
};

export default App;
