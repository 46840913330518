import React from 'react';
import { Menu } from '../common';

const UserMenu = ({ intl, onSignOut }) => {
  return (
    <Menu>
      <Menu.Item onClick={onSignOut}>{intl.signOut}</Menu.Item>
    </Menu>
  );
};

export default UserMenu;
