import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withServices } from '../../services';
import {
  Typography,
  UserOutlined,
  UserGroupAddOutlined,
} from '../../components/common';
import StaffOptionCard from '../../components/staff/StaffOptionCard';

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const { Title } = Typography;

const StaffHomePage = ({
  intl: {
    messages: { staffHomePage: intl },
  },
  userStore: { user },
  services: { navigation },
}) => {
  const history = useHistory();

  const onCheckIn = () => {
    if (user.hasCheckedIn) {
      history.push(navigation.recommendation.toUrl());
    } else {
      history.push(navigation.checkIn.toUrl());
    }
  };

  const onListPeople = () => {
    history.push(navigation.listPeople.toUrl());
  };

  return (
    <div style={styles.pageContainer}>
      <Title level={2} style={styles.title}>
        {intl.titleText(user)}
      </Title>
      <StaffOptionCard
        Icon={UserOutlined}
        title={intl.selfCheckInOptionTitle}
        description={intl.selfCheckInOptionDescription}
        onClick={onCheckIn}
      />
      {user.canListPeople && (
        <StaffOptionCard
          Icon={UserGroupAddOutlined}
          title={intl.checkInOthersOptionTitle}
          description={intl.checkInOthersOptionDescription}
          onClick={onListPeople}
        />
      )}
    </div>
  );
};

export default withServices(
  injectIntl(inject('userStore')(observer(StaffHomePage)))
);
