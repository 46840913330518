import React from 'react';
import { Layout } from '../common';
import logo from '../../assets/images/logo.png';

const styles = {
  headerContainer: {
    textAlign: 'center',
    paddingTop: 0,
  },
  logo: {
    maxHeight: '100%',
  },
};

const { Header: AntHeader } = Layout;

const Header = () => {
  return (
    <AntHeader style={styles.headerContainer}>
      <img src={logo} alt="logo" style={styles.logo} />
    </AntHeader>
  );
};

export default Header;
