import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withServices } from '../../services';
import { Typography } from '../../components/common';
import StudentConfirmationCard from '../../components/student/StudentConfirmationCard';

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const { Title } = Typography;

const StudentConsentPage = ({
  intl: {
    messages: { studentHomePage: intl },
  },
  userStore: { user, signOut },
  services: { navigation },
}) => {
  const history = useHistory();

  const onCancel = () => {
    signOut();
    history.push(`${navigation.login.toUrl()}?auth=password`);
  };

  const onSubmit = () => {
    if (user.hasCheckedIn) {
      history.push(navigation.recommendation.toUrl());
    } else {
      history.push(navigation.checkIn.toUrl());
    }
  };

  return (
    <div style={styles.pageContainer}>
      <Title level={2} style={styles.title}>
        {intl.titleText}
      </Title>
      <StudentConfirmationCard
        intl={intl.studentConfirmationCard}
        name={user.name}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default withServices(
  injectIntl(inject('userStore')(observer(StudentConsentPage)))
);
