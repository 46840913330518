import React from 'react';
import { Input } from '../common';

const StudentNameSearch = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  return (
    <Input
      style={{ width: '100%' }}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      allowClear
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default StudentNameSearch;
