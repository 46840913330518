import React from 'react';
import config from '../../config';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  GoogleButton,
  Input,
  Typography,
} from '../common';
import LabelWithIcon from './LabelWithIcon';

const styles = {
  title: {
    textAlign: 'center',
  },
  message: {
    textAlign: 'left',
    display: 'block',
    marginBottom: 24,
  },
  error: {
    marginBottom: 20,
  },
  loginButton: {
    width: '100%',
  },
  googleButtonContainer: {
    width: '100%',
    textAlign: 'center',
  },
};

const { Title, Text } = Typography;

const LoginForm = ({ intl, onSubmit, onGoogleSubmit, auth, showError }) => {
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const checkBoxLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 12 } },
  };

  const loginButtonLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 8 } },
  };

  const googleLoginButtonLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 8 } },
  };

  return (
    <Form {...formLayout} name="login" colon={false} onFinish={onSubmit}>
      <Title level={2} style={styles.title}>
        {intl.titleText}
      </Title>
      <Text style={styles.message}>{intl.appMessage}</Text>
      {showError && (
        <Alert
          style={styles.error}
          message={intl.loginErrorMessage}
          type="error"
          closable
        />
      )}
      {auth === 'password' && (
        <div>
          <Form.Item
            label={
              <LabelWithIcon
                label={intl.usernameLabel}
                helpMessage={intl.usernameHelpMessage}
              />
            }
            name="username"
            required={false}
            rules={[{ required: true, message: intl.usernameRequiredMessage }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <LabelWithIcon
                label={intl.passwordLabel}
                helpMessage={intl.passwordHelpMessage}
              />
            }
            name="password"
            required={false}
            rules={[{ required: true, message: intl.passwordRequiredMessage }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            {...checkBoxLayout}
            name="remember"
            valuePropName="checked"
            required={false}
            rules={[{ required: true, message: intl.parentRequiredMessage }]}
          >
            <Checkbox>{intl.parentCheckboxLabel}</Checkbox>
          </Form.Item>
          <Form.Item {...loginButtonLayout}>
            <Button type="primary" htmlType="submit" style={styles.loginButton}>
              {intl.loginButton}
            </Button>
          </Form.Item>
        </div>
      )}
      {auth === 'google' && (
        <Form.Item {...googleLoginButtonLayout}>
          <div style={styles.googleButtonContainer}>
            <GoogleButton
              text={intl.googleButton}
              clientId={config.google.clientId}
              onSuccess={onGoogleSubmit}
              onFailure={() => console.log('failed')}
            />
          </div>
        </Form.Item>
      )}
    </Form>
  );
};

export default LoginForm;
