import React from 'react';
import { List } from '../common';
import StudentListItem from './StudentListItem';

const StudentList = ({
  students,
  onCheckStudentStatus,
  onCheckInStudent,
  intl,
}) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={students}
      renderItem={student => (
        <StudentListItem
          student={student}
          onCheckStatus={onCheckStudentStatus.bind(this, student.id)}
          onCheckIn={onCheckInStudent.bind(this, student.id)}
          intl={intl.studentListItem}
        />
      )}
    />
  );
};

export default StudentList;
