import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withServices } from '../services';

const AuthenticatedRoute = ({
  appStore: { isReady },
  userStore: { user },
  services: { navigation },
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (!isReady) {
          return <div />;
        } else if (!user) {
          return <Redirect to={navigation.login.path} />;
        } else {
          return children;
        }
      }}
    />
  );
};

export default withServices(
  inject('appStore', 'userStore')(observer(AuthenticatedRoute))
);
