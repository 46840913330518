import ax from 'axios';

class ApiError extends Error {
  constructor(name, message, url, data) {
    super(message);
    this.name = name;
    this.fullUrl = url;
    this.data = data;
  }
}

export const createApiService = ({
  baseUrl: baseURL,
  timeout,
  mockEnabled,
  mockResponseDelay,
}) => {
  const axios = ax.create({
    baseURL,
    timeout,
  });

  axios.interceptors.response.use(
    response => response,
    error => {
      let name = 'ApiError: ';
      let message = 'No response';
      let url = null;
      let data = {};

      if (error.response) {
        if (error.response.config) {
          if (error.response.config.url) name += `${error.response.config.url}`;
        }

        if (error.response.request) {
          if (error.response.request.status)
            name += ` ${error.response.request.status}`;
          if (error.response.request.statusText)
            name += ` ${error.response.request.statusText}`;
          if (error.response.request.responseURL)
            url = error.response.request.responseURL;
        }

        if (error.response.data) {
          const resp = error.response.data;
          message = resp.message;
          data = resp;
        }
      }

      const errorObj = new ApiError(name, message, url, data);
      return Promise.reject(errorObj);
    }
  );

  if (mockEnabled) {
    // eslint-disable-next-line global-require
    const AxiosMock = require('./mock').default;
    AxiosMock.init(axios, mockResponseDelay);
  }

  return {
    setAccessToken: token => {
      axios.defaults.headers = {
        ...axios.defaults.headers,
        Authorization: `Bearer ${token}`,
      };
    },
    clearAccessToken: () => {
      delete axios.defaults.headers['Authorization'];
    },
    signIn: (username, password) => {
      return axios.post('/authenticateWithPassword', {
        username,
        password,
      });
    },
    signInWithGoogle: token => {
      return axios.post('/authenticateWithGoogleOAuthToken', {
        token,
      });
    },
    submitCheckIn: (subjectId, checkIn) => {
      const { symptomResults: symptoms, temperature, screenDt } = checkIn;

      return axios.post('/screenPerson', {
        subjectId,
        symptoms,
        temperature,
        screenDt,
      });
    },
    getManagedUsers: filter => {
      return axios.get('/listPeople', {
        params: { filter: filter.text, group: filter.group },
      });
    },
    getPermissions: () => {
      return axios.get('/getPermissions');
    },
    getStatus: () => {
      return axios.get('/getStatus');
    },
    getSymptomList: () => {
      return axios.get('/listSymptoms');
    },
  };
};
