import React from 'react';
import { Select } from '../common';

const { Option } = Select;

const StudentGroupFilter = ({
  studentGroups,
  defaultStudentGroup,
  value,
  onChange,
}) => {
  return (
    <Select
      defaultValue={defaultStudentGroup}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
    >
      {studentGroups.map((studentGroup, index) => (
        <Option key={studentGroup.id} value={studentGroup.id}>
          {studentGroup.name}
        </Option>
      ))}
    </Select>
  );
};

export default StudentGroupFilter;
