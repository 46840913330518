import { types, flow } from 'mobx-state-tree';
import moment from 'moment';
import { withServices, withLoadingStatus } from '../../extensions';
import { DateType } from '../../types';

const UserGroup = types.model('UserGroup', {
  id: types.identifier,
  name: types.string,
});

const User = types
  .model('User', {
    id: types.identifier,
    givenName: types.string,
    familyName: types.string,
    birthDate: types.maybeNull(DateType),
    // Additional fields
    name: types.string,
    isShowingSymptoms: types.maybeNull(types.boolean),
    managedGroups: types.array(UserGroup),
    permissions: types.array(types.string),
  })
  .views(self => ({
    get needsConsent() {
      const now = moment();
      const dob = moment(self.birthDate);
      return now.diff(dob, 'years') <= 18;
    },
    get canListPeople() {
      return self.permissions.includes('list-people');
    },
    get isStaff() {
      return self.canListPeople;
    },
    get hasCheckedIn() {
      return (
        self.isShowingSymptoms === true || self.isShowingSymptoms === false
      );
    },
  }));

const UserStore = types.compose(
  withLoadingStatus(),
  withServices,
  types
    .model('UserStore', {
      user: types.maybeNull(User),
      managedUsers: types.maybe(types.array(User)),
    })
    .actions(self => ({
      signIn: flow(function* signIn(username, password) {
        self.startFetching();
        try {
          let response = yield self.services.api.signIn(username, password);
          const { identity, accessToken } = response.data;
          self.services.api.setAccessToken(accessToken);
          response = yield self.services.api.getPermissions(identity.id);
          const { permissions, managedGroups } = response.data;
          response = yield self.services.api.getStatus();
          const { status } = response.data;
          self.user = {
            ...identity,
            name: `${identity.givenName} ${identity.familyName}`,
            permissions,
            managedGroups,
            isShowingSymptoms:
              status === 'unknown' ? null : status === 'symptoms',
          };
          self.endFetching();
          return { isSuccessful: true, authenticatedUser: self.user };
        } catch (e) {
          self.endFetching(e);
          return { isSuccessful: false, authenticatedUser: null };
        }
      }),
      signInWithGoogle: flow(function* signIn(googleToken) {
        self.startFetching();
        try {
          let response = yield self.services.api.signInWithGoogle(googleToken);
          const { identity, accessToken } = response.data;
          self.services.api.setAccessToken(accessToken);
          response = yield self.services.api.getPermissions(identity.id);
          const { permissions, managedGroups } = response.data;
          response = yield self.services.api.getStatus();
          const { status } = response.data;
          self.user = {
            ...identity,
            name: `${identity.givenName} ${identity.familyName}`,
            birthDate: identity.birthDate || null,
            permissions,
            managedGroups,
            isShowingSymptoms:
              status === 'unknown' ? null : status === 'symptoms',
          };
          self.endFetching();
          return { isSuccessful: true, authenticatedUser: self.user };
        } catch (e) {
          self.endFetching(e);
          return { isSuccessful: false, authenticatedUser: null };
        }
      }),
      signOut: flow(function* signOut() {
        self.services.api.clearAccessToken();
        self.user = null;
        self.managedUsers = undefined;
      }),
      submitCheckIn: flow(function* submitCheckIn(userId, checkIn) {
        self.startFetching();
        try {
          const response = yield self.services.api.submitCheckIn(
            userId,
            checkIn
          );
          const { passed } = response.data;

          if (userId === self.user.id) {
            self.user.isShowingSymptoms = !passed;
          } else {
            const managedUserIndex = self.managedUsers.findIndex(
              u => u.id === userId
            );

            if (managedUserIndex !== -1) {
              self.managedUsers[managedUserIndex].isShowingSymptoms = !passed;
            }
          }

          self.endFetching();
          return true;
        } catch (e) {
          self.endFetching(e);
          return false;
        }
      }),
      getManagedUsers: flow(function* getManagedUsers(userId, filter) {
        self.startFetching();
        try {
          const response = yield self.services.api.getManagedUsers(
            userId,
            filter
          );
          const { people } = response.data;
          self.managedUsers = people.map(u => ({
            ...u,
            birthDate: u.birthDate || null,
            name: `${u.givenName} ${u.familyName}`,
            isShowingSymptoms:
              u.status === 'unknown' ? null : u.status === 'symptoms',
          }));
          self.endFetching();
          return true;
        } catch (e) {
          self.endFetching(e);
          return false;
        }
      }),
    }))
);

export default UserStore;
