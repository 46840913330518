const en = {
  app: {
    header: {
      userMenu: {
        signOut: 'Sign out',
      },
    },
    footer: {
      termsOfUseLinkText: 'Terms of Use',
      privacyPolicyLinkText: 'Privacy Policy',
      termsOfUseMessageText: `Only authorized use by FSD145 staff or students is permitted.
      
      All individuals under the age of 18 must be supervisored by a parent or legal guardian.
      
      All screening questions must be answered to one's best ability and understanding.
      
      This is not a diagonsis for COVID-19 or any other disease or ailment. If you have concerns about your health, contact your primary care physician.`,
      privacyPolicyMessageText: `All data collected is stored on an encrypted file system in a secure facility in the USA.

      No data is transferred, sold, or shared with any third party.
      
      FSD145 may use the data to determine whether or not a particular individual should be permitted to be at school.`,
    },
  },
  loginPage: {
    studentLinkText: 'Not a student? Go to staff login.',
    staffLinkText: 'Not staff? Go to student login.',
    loginForm: {
      titleText: 'COVID-19 Symptom Screening',
      appMessage:
        'In an effort to protect our community from illness, we ask that all staff and students take a quick minute every morning to ask themselves these questions which are based on guidelines from the CDC.',
      usernameLabel: 'Student Id',
      usernameRequiredMessage: 'Please enter the student id.',
      usernameHelpMessage:
        'Your student identification number. It is usually a 9 digit number like 123456789.',
      passwordLabel: 'Student Date of Birth',
      passwordRequiredMessage: "Please enter the student's date of birth.",
      passwordHelpMessage:
        'The birthdate of the student in month/day/year format. For example, enter January 10th 2008 as 1/10/2008.',
      parentCheckboxLabel: 'I agree to the terms of use.',
      parentRequiredMessage:
        'You must read and agree to the terms of use before proceeding.',
      loginButton: 'Log In',
      googleButton: 'FSD145 Staff Log In',
      loginErrorMessage:
        'Login failed. Only fsd145.org email addresses may be used.',
    },
  },
  checkInPage: {
    titleText: name => `${name}`,
    dateText: 'This COVID-19 symptom screening is for ',
    checkInForm: {
      certifyQuestionText: name =>
        `Has ${name} experienced any of the following in the past 24 hours?`,
      symptoms: {
        fever: 'Felt feverish',
        coughing: 'A cough',
        'shortness-of-breath': 'New shortness of breath',
        fatigue: 'Fatigue',
        'body-aches': 'Muscle or body aches',
        'loss-of-taste-or-smell': 'New loss of taste or smell',
        'sore-throat': 'A sore throat',
        congestion: 'Congestion or runny nose',
        nausea: 'New feelings of nausea, vomiting, or diarrhea',
        diarrhea: 'Diarrhea',
      },
      temperatureQuestionText: name => `What is ${name}'s current temperature?`,
      degreeFahrenheight: '° F',
      degreeCelsius: '° C',
      temperatureErrorText:
        'Enter a valid body temperature in degrees Fahrenheit.',
      submitButton: 'Submit Certification',
      errorMessageText: 'You have to report on each symptom in the list',
      questionOptions: {
        noButton: 'No',
        yesButton: 'Yes',
      },
    },
  },
  recommendationPage: {
    selfCheckIn: {
      passTitle: () => 'Thank You for Checking In!',
      failTitle: () => 'You are Showing COVID-19 Symptoms!',
      passMessageText: () =>
        'Based on your responses, you are cleared to follow the appropriate schedule for today.',
      failMessageText: () =>
        'Based on your responses, you should stay home today and contact the appropriate personnel.',
      additionalPassInfoText: '',
      additionalFailInfoText: '',
      phone1: '',
    },
    checkInForOthers: {
      passTitle: name => `You have checked in ${name}!`,
      failTitle: name => `${name} is Showing COVID-19 Symptoms!`,
      passMessageText: name =>
        `Based on your responses, ${name} cleared to follow the appropriate schedule for today.`,
      failMessageText: name =>
        `Based on your responses, ${name} should stay home today and contact the appropriate personnel.`,
      additionalPassInfoText: '',
      additionalFailInfoText: '',
      phone1: '',
    },
    actions: {
      goToHome: 'Home',
      goToListPeople: 'Back to Student List',
      signOut: 'Sign out',
    },
  },
  staffHomePage: {
    titleText: user => user.name,
    selfCheckInOptionTitle: 'Self Certify',
    selfCheckInOptionDescription:
      'Perform a self-certification for current COVID-19 symptoms.',
    checkInOthersOptionTitle: 'Screen Others',
    checkInOthersOptionDescription:
      'Perform a COVID-19 symptom screening for another person.',
  },
  studentHomePage: {
    titleText: '',
    studentConfirmationCard: {
      message: name =>
        `By continuing, I certify that I am the legal guardian, parent, or person who enrolled ${name}.`,
      submitButton: 'Continue',
      cancelButton: 'Cancel',
    },
  },
  studentsCheckInPage: {
    titleText: 'COVID-19 Symptom Screening',
    studentFilters: {
      studentNameSearchLabel: 'Select a person',
      allStudentsLabel: 'Everybody',
    },
    studentList: {
      studentListItem: {
        studentStatusLabels: {
          unknown: 'Has not checked in yet',
          showingSymptoms: 'Showing symptoms',
          notShowingSymptoms: 'Not showing symptoms',
        },
      },
    },
    studentStatusModal: {
      passTitle: student => `Checked in at ${student.checkInTime}`,
      failTitle: student => `Checked in at ${student.checkInTime}`,
      passMessageText: student =>
        `${student.name} is not showing symptoms today!`,
      failMessageText: student =>
        `${student.name} is showing COVID-19 symptoms!`,
      reCheckInActionLinkPrefix: 'If this result needs to be updated, you may ',
      reCheckInActionLink: 'redo the screening questionnaire',
      reCheckInActionLinkSuffix: '.',
    },
  },
};

export default en;
