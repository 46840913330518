import { types } from 'mobx-state-tree';
import moment from 'moment';

const START_LOADING_THRESHOLD_MS = 200;
const MIN_LOADING_TIME_MS = 500;

const withLoadingStatus = (config = { useFetchStatus: false }) =>
  types
    .model({
      isInitiated: types.optional(types.boolean, false),
      fetchDate: types.maybeNull(types.Date),
      loadDate: types.maybeNull(types.Date),
      error: types.maybeNull(types.string),
    })
    .views(self => ({
      get fetchDt() {
        return self.fetchDate ? moment(self.fetchDate) : null;
      },
      set fetchDt(momentDt) {
        self.fetchDate = momentDt ? momentDt.toDate() : null;
      },
      get loadDt() {
        return self.loadDate ? moment(self.loadDate) : null;
      },
      set loadDt(momentDt) {
        self.loadDate = momentDt ? momentDt.toDate() : null;
      },
      get isFetching() {
        return self.fetchDt !== null;
      },
      get isLoading() {
        return self.loadDt !== null;
      },
      get hasError() {
        return !!self.error;
      },
    }))
    .actions(self => ({
      startFetching() {
        self.fetchDt = moment();
        self.error = null;

        if (config.useFetchStatus) {
          self.startLoading();
        } else {
          self.startLoadingTimeout = setTimeout(() => {
            self.startLoading();
          }, START_LOADING_THRESHOLD_MS);
        }
      },
      endFetching(error) {
        self.isInitiated = true;
        self.fetchDt = null;
        if (error) {
          self.error = `${error.message}\n${error.stack}`;
          console.error(error);
        }

        if (config.useFetchStatus || !self.isLoading) {
          self.endLoading();
        } else {
          const endLoadingDt = self.loadDt.add(MIN_LOADING_TIME_MS, 'ms');
          const now = moment();

          if (now.isSameOrAfter(endLoadingDt)) {
            self.endLoading();
          } else {
            self.endLoadingTimeout = setTimeout(() => {
              self.endLoading();
            }, endLoadingDt.diff(now));
          }
        }
      },
      startLoading() {
        clearTimeout(self.endLoadingTimeout);
        self.loadDt = moment();
      },
      endLoading() {
        clearTimeout(self.startLoadingTimeout);
        self.loadDt = null;
      },
    }));

export default withLoadingStatus;
