import React from 'react';
import { Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

const AnonymousRoute = ({ appStore: { isReady }, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (isReady) {
          return children;
        } else {
          return <div />;
        }
      }}
    />
  );
};

export default inject('appStore')(observer(AnonymousRoute));
