import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Layout, Dropdown, DownOutlined, Typography } from '../common';
import UserMenu from './UserMenu';

const styles = {
  layout: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  headerContainer: {
    height: 48,
    marginBottom: 16,
    borderBottom: '1px solid #ccc',
  },
  headerContentContainer: {
    width: '100%',
    height: '100%',
    padding: '12px 16px',
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  userContainer: {
    flexGrow: 1,
  },
  localesContainer: {
    height: '100%',
  },
  localeIcon: {
    height: '100%',
    width: 'auto',
    cursor: 'pointer',
    opacity: 0.7,
  },
  localeIconSelected: {
    opacity: 1,
  },
};

const { Text } = Typography;

const AppContainer = ({
  locales,
  locale,
  onLocaleChange,
  children,
  appStore,
  userStore: { user, signOut },
  intl: {
    messages: {
      app: { header: intl },
    },
  },
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    appStore.getSymptoms().then(isSuccessful => {
      // TODO do something if it fails to get symptom list
    });
  }, [appStore]);
  const Menu = <UserMenu intl={intl.userMenu} onSignOut={signOut} />;

  return (
    <Layout style={styles.layout}>
      <div style={styles.headerContainer}>
        <div style={styles.headerContentContainer}>
          <div style={styles.userContainer}>
            {user && (
              <Dropdown overlay={Menu}>
                <Text>
                  {user.name} <DownOutlined />
                </Text>
              </Dropdown>
            )}
          </div>
          <div style={styles.localesContainer}>
            {Object.keys(locales).map(key => {
              const l = locales[key];
              let iconStyle = styles.localeIcon;
              if (key === locale) {
                iconStyle = {
                  ...styles.localeIcon,
                  ...styles.localeIconSelected,
                };
              }
              return (
                <img
                  key={key}
                  src={l.icon}
                  alt={l.label}
                  onClick={onLocaleChange.bind(this, key)}
                  style={iconStyle}
                  className="locale-icon"
                />
              );
            })}
          </div>
        </div>
      </div>
      {children}
    </Layout>
  );
};

export default injectIntl(
  inject('appStore', 'userStore')(observer(AppContainer))
);
