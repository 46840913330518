import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';

class GoogleButton extends Component {
  render() {
    const { text, clientId, onSuccess, onFailure } = this.props;

    return (
      <GoogleLogin
        clientId={clientId}
        onSuccess={onSuccess}
        onFailure={onFailure}
        buttonText={text}
      />
    );
  }
}

export { GoogleButton };
