import { types, applySnapshot } from 'mobx-state-tree';
import { persist } from 'mst-persist';
import AppStore from './models/app/AppStore';
import UserStore from './models/user/UserStore';

const storeModel = types.model({
  appStore: AppStore,
  userStore: UserStore,
});

const createStore = ({ localStorageKey }) => {
  const appStore = AppStore.create({
    isReady: false,
    symptoms: [],
  });

  const userStore = UserStore.create({
    user: null,
  });

  const store = storeModel.create({
    appStore,
    userStore,
  });

  persist(localStorageKey, store, {
    // TODO: whitelisting causes issues with an undefined appStore
    // whitelist: ['userStore'],
  }).then(() => {
    applySnapshot(store.appStore, { isReady: true, symptoms: [] });
  });

  return store;
};

export { createStore };
