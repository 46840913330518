import React from 'react';
import { Popover, QuestionCircleFilled } from '../common';

const LabelWithIcon = ({ label, helpMessage }) => {
  return (
    <div>
      {label}{' '}
      <Popover
        placement="top"
        title={label}
        content={helpMessage}
        trigger="click"
      >
        <QuestionCircleFilled />
      </Popover>
    </div>
  );
};

export default LabelWithIcon;
