import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Typography } from '../common';
import BasicPolicyModal from './BasicPolicyModal';

const styles = {
  footer: {
    textAlign: 'center',
  },
  policyLink: {
    cursor: 'pointer',
  },
};

const { Footer: AntFooter } = Layout;

const { Text } = Typography;

const Footer = ({
  intl: {
    messages: {
      app: { footer: intl },
    },
  },
}) => {
  const [shownModalTitle, setShownModalTitle] = useState(null);
  const [shownModalInfo, setShownModalInfo] = useState(null);

  const showTermsOfUse = () => {
    setShownModalTitle(intl.termsOfUseLinkText);
    setShownModalInfo(intl.termsOfUseMessageText);
  };

  const showPrivacyPolicy = () => {
    setShownModalTitle(intl.privacyPolicyLinkText);
    setShownModalInfo(intl.privacyPolicyMessageText);
  };

  const hideModal = () => {
    setShownModalTitle(null);
    setShownModalInfo(null);
  };

  return (
    <AntFooter style={styles.footer}>
      <Text type="secondary" style={styles.policyLink} onClick={showTermsOfUse}>
        {intl.termsOfUseLinkText}
      </Text>{' '}
      <Text type="secondary">{' | '}</Text>
      <Text
        type="secondary"
        style={styles.policyLink}
        onClick={showPrivacyPolicy}
      >
        {intl.privacyPolicyLinkText}
      </Text>
      <BasicPolicyModal
        visible={!!shownModalTitle}
        title={shownModalTitle}
        info={shownModalInfo}
        onOk={hideModal}
        onCancel={hideModal}
      />
    </AntFooter>
  );
};

export default injectIntl(Footer);
