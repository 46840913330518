import React from 'react';
import config from '../config';
import { createApiService } from './api';
import { createNavigationService } from './navigation';

let services = {};

const initServices = () => {
  services = {
    config,
    api: createApiService(config.api),
    navigation: createNavigationService(),
  };

  return services;
};

const fetchServices = () => services;

const withServices = Component => props => (
  <Component services={services} {...props} />
);

export { initServices, fetchServices, withServices };
