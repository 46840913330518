import React from 'react';
import {
  List,
  Avatar,
  CheckCircleOutlined,
  StopOutlined,
  QuestionCircleOutlined,
  Typography,
} from '../common';

const styles = {
  avatar: {
    backgroundColor: '#f07426',
  },
  statusIcon: {
    marginRight: 8,
  },
  statusIconUnknown: {},
  statusIconShowingSymptoms: {
    color: '#dc3545',
  },
  statusIconNotShowingSymptoms: {
    color: '#28a745',
  },
  statusTextUnknown: {},
  statusTextShowingSymptoms: {
    color: '#dc3545',
  },
  statusTextNotShowingSymptoms: {},
};

const { Text } = Typography;

const StudentStatus = ({ isShowingSymptoms, intl }) => {
  let status = intl.studentStatusLabels.unknown;
  let statusTextStyle = styles.statusTextUnknown;
  let Icon = QuestionCircleOutlined;
  let iconStyle = styles.statusIconUnknown;

  if (isShowingSymptoms === true || isShowingSymptoms === false) {
    status = isShowingSymptoms
      ? intl.studentStatusLabels.showingSymptoms
      : intl.studentStatusLabels.notShowingSymptoms;

    statusTextStyle = isShowingSymptoms
      ? styles.statusTextShowingSymptoms
      : styles.statusTextNotShowingSymptoms;

    Icon = isShowingSymptoms ? StopOutlined : CheckCircleOutlined;
    iconStyle = isShowingSymptoms
      ? styles.statusIconShowingSymptoms
      : styles.statusIconNotShowingSymptoms;
  }

  return (
    <div>
      <Icon style={{ ...styles.statusIcon, ...iconStyle }} />
      <Text style={statusTextStyle} type="secondary">
        {status}
      </Text>
    </div>
  );
};

const StudentListItem = ({ student, onCheckStatus, onCheckIn, intl }) => {
  let initials = student.name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

  const hasCheckedIn =
    student.isShowingSymptoms === true || student.isShowingSymptoms === false;

  const onClick = () => {
    if (hasCheckedIn) {
      onCheckStatus();
    } else {
      onCheckIn();
    }
  };

  return (
    <List.Item onClick={onClick}>
      <List.Item.Meta
        avatar={<Avatar style={styles.avatar}>{initials}</Avatar>}
        title={student.name}
        description={
          <StudentStatus
            isShowingSymptoms={student.isShowingSymptoms}
            intl={intl}
          />
        }
      />
    </List.Item>
  );
};

export default StudentListItem;
