import moment from 'moment';

const userGroups = [
  {
    id: 'user-group-1',
    name: '1st Grade',
    users: ['student-1', 'student-2'],
  },
  {
    id: 'user-group-2',
    name: '2nd Grade',
    users: ['student-3'],
  },
];

const users = [
  {
    id: 'student-1',
    givenName: 'Carl',
    familyName: 'Smith',
    birthDate: moment().subtract(13, 'years').format(),
    auxiliary: {
      username: 'student-1',
      password: '01/01/2011',
      permissions: [],
      status: 'symptoms',
      userGroups: ['user-group-1'],
      managedGroups: [],
    },
  },
  {
    id: 'student-2',
    givenName: 'Mark',
    familyName: 'Wall',
    birthDate: moment().subtract(14, 'years').format(),
    // For fake auth:
    auxiliary: {
      username: 'student-2',
      password: '02/02/2012',
      status: 'no-symptoms',
      permissions: [],
      userGroups: ['user-group-1'],
      managedGroups: [],
    },
  },
  {
    id: 'student-3',
    givenName: 'Susan',
    familyName: 'Stewart',
    birthDate: moment().subtract(20, 'years').format(),
    // For fake auth:
    auxiliary: {
      username: 'student-3',
      password: '03/03/2013',
      status: 'unknown',
      permissions: [],
      userGroups: ['user-group-2'],
      managedGroups: [],
    },
  },
  {
    id: 'staff-1',
    givenName: 'Mister',
    familyName: 'John',
    birthDate: moment().subtract(30, 'years').format(),
    // For fake auth:
    auxiliary: {
      userGroups: [],
      status: 'unknown',
      managedGroups: [
        { id: 'user-group-1', name: '1st Grade' },
        { id: 'user-group-2', name: '2nd Grade' },
      ],
      permissions: ['list-people', 'screen'],
    },
  },
];

export { users, userGroups };
