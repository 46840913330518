import React from 'react';
import { Layout, Card } from '../common';

const styles = {
  contentContainer: {
    padding: 16,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    width: '100%',
    minHeight: '100%',
  },
};

const { Content } = Layout;

const PageContainer = ({ children }) => {
  return (
    <Content style={styles.contentContainer}>
      <Card style={styles.card}>{children}</Card>
    </Content>
  );
};

export default PageContainer;
