import React from 'react';
import { Form, Radio, ExclamationCircleFilled } from '../common';

const styles = {
  error: {},
};

const Question = ({ question, intl }) => {
  const options = [intl.noButton, intl.yesButton];

  return (
    <Form.Item
      name={question.id}
      label={question.label}
      required={false}
      rules={[
        {
          required: true,
          message: <ExclamationCircleFilled className="question-error-icon" />,
        },
      ]}
      className="left-form-item"
    >
      <Radio.Group
        options={options}
        optionType="button"
        buttonStyle="solid"
        className="question-radio-group"
      />
    </Form.Item>
  );
};

export default Question;
