import Location from 'react-app-location';

const createNavigationService = () => ({
  root: new Location('/'),
  login: new Location('/login'),
  home: new Location('/home'),
  consent: new Location('/consent'),
  checkIn: new Location('/check-in'),
  recommendation: new Location('/recommendation'),
  listPeople: new Location('/list-people'),
});

export { createNavigationService };
