import React from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl, FormattedDate } from 'react-intl';
import { observer, inject } from 'mobx-react';
import qs from 'qs';
import { withServices } from '../../services';
import { Typography } from '../../components/common';
import CheckInForm from '../../components/checkIn/CheckInForm';

const styles = {
  pageContainer: {},
  formContainer: {
    width: '100%',
  },
  formTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 24,
  },
};

const { Title, Text } = Typography;

const CheckInPage = ({
  intl: {
    messages: { checkInPage: intl },
  },
  appStore: { symptoms },
  userStore: { user, managedUsers, submitCheckIn },
  services: { navigation },
}) => {
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  let student = user;

  if (search['user-id']) {
    student = managedUsers.find(s => s.id === search['user-id']);
  }

  const studentName = (student && student.name) || 'Unknown';
  const studentId = student && student.id;

  const onSubmit = values => {
    const symptomResults = [];
    const temperature = values.temp;
    delete values.temp;

    Object.entries(values).forEach(([symptom, value]) => {
      if (value === 'Yes') {
        symptomResults.push(symptom);
      }
    });

    submitCheckIn(studentId, {
      symptomResults,
      temperature,
      screenDt: moment().format(),
    }).then(isSuccess => {
      // TODO some more error handling here.
      let redirectTo = navigation.recommendation.toUrl();

      if (search['user-id']) {
        redirectTo = `${redirectTo}?user-id=${search['user-id']}`;
      }

      history.push(redirectTo);
    });
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formTitleContainer}>
        <Title level={3}>{intl.titleText(studentName)}</Title>
        <Text>
          {intl.dateText}
          <FormattedDate
            value={moment().format()}
            weekday="long"
            month="long"
            day="numeric"
          />
        </Text>
      </div>
      <CheckInForm
        intl={intl.checkInForm}
        symptoms={symptoms}
        name={studentName}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default withServices(
  injectIntl(inject('appStore', 'userStore')(observer(CheckInPage)))
);
