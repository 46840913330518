import React, { useState } from 'react';
import { Button, Form, InputNumber, Row, Col, Typography } from '../common';
import Question from './Question';

const TEMP_C_MIN = 32;
const TEMP_C_MAX = 43;
const TEMP_F_MIN = 90;
const TEMP_F_MAX = 110;

const styles = {
  leadingQuestion: {
    textAlign: 'left',
    marginBottom: 20,
  },
  temperatureQuestion: {
    textAlign: 'left',
  },
  degree: {
    alignItems: 'center',
    height: 32,
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 14,
    display: 'inline-flex',
    marginLeft: 5,
  },
  error: {
    color: 'red',
  },
};

const { Text } = Typography;

const CheckInForm = ({ intl, name, symptoms, onSubmit }) => {
  const [error, setError] = useState(false);
  const [temperatureLabel, setTemperatureLabel] = useState(
    intl.degreeFahrenheight
  );
  const [temperatureError, setTemperatureError] = useState({});
  const { symptoms: symptomIntl } = intl;

  const questions = symptoms.map(id => ({
    id,
    label: symptomIntl[id] || id,
  }));

  const formLayout = {
    labelCol: {
      sm: { offset: 2, span: 8 },
      md: { offset: 4, span: 8 },
      lg: { offset: 4, span: 8 },
    },
    wrapperCol: {
      sm: { offset: 4, span: 8 },
      md: { offset: 2, span: 8 },
      lg: { offset: 2, span: 8 },
    },
  };

  const buttonLayout = {
    wrapperCol: {
      sm: { offset: 2, span: 6 },
      md: { offset: 4, span: 6 },
      lg: { offset: 4, span: 6 },
    },
  };

  const onError = () => {
    setError(true);
  };

  const clearError = () => {
    setError(false);
  };

  const validateTemperature = tempValue => {
    if (
      tempValue < TEMP_C_MIN ||
      (tempValue > TEMP_C_MAX && tempValue < TEMP_F_MIN) ||
      tempValue > TEMP_F_MAX
    ) {
      return {
        validateStatus: 'error',
        errorMsg: intl.temperatureErrorText,
      };
    }

    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };

  const checkTemperature = event => {
    setTemperatureError({
      ...validateTemperature(event.target.value),
    });
  };

  const updateTemperatureLabel = value => {
    if (value >= TEMP_C_MIN && value <= TEMP_C_MAX) {
      setTemperatureLabel(intl.degreeCelsius);
    } else {
      setTemperatureLabel(intl.degreeFahrenheight);
    }
  };

  return (
    <Form
      {...formLayout}
      name="checking"
      colon={false}
      onFinish={onSubmit}
      onFinishFailed={onError}
      onValuesChange={clearError}
    >
      {error && (
        <Row>
          <Col
            sm={{ offset: 2, span: 22 }}
            md={{ offset: 4, span: 20 }}
            lg={{ offset: 4, span: 20 }}
            style={{ textAlign: 'left' }}
          >
            <Text style={styles.error}>{intl.errorMessageText}</Text>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          sm={{ offset: 2, span: 22 }}
          md={{ offset: 4, span: 20 }}
          lg={{ offset: 4, span: 20 }}
        >
          <div style={styles.leadingQuestion}>
            {intl.certifyQuestionText(name)}
          </div>
        </Col>
      </Row>
      {questions.map((question, index) => (
        <Question key={index} question={question} intl={intl.questionOptions} />
      ))}
      <Form.Item
        style={styles.temperatureQuestion}
        label={intl.temperatureQuestionText(name)}
        validateStatus={temperatureError.validateStatus}
        help={temperatureError.errorMsg}
      >
        <Form.Item
          name="temp"
          required={false}
          noStyle
          className="left-form-item"
        >
          <InputNumber
            onBlur={checkTemperature}
            onChange={updateTemperatureLabel}
          />
        </Form.Item>
        <span style={styles.degree}>{temperatureLabel}</span>
      </Form.Item>
      <Form.Item {...buttonLayout}>
        <Button type="primary" htmlType="submit">
          {intl.submitButton}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CheckInForm;
